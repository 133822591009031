const Cities = [
  "An Giang",
  "Bà Rịa Vũng Tàu",
  "Bình Dương",
  "Bình Phước",
  "Bình Thuận",
  "Bình Định",
  "Bạc Liêu",
  "Bắc Cạn",
  "Bắc Giang",
  "Bắc Ninh",
  "Bến Tre",
  "Cao Bằng",
  "Cà Mau",
  "Cần Thơ",
  "Đà Nẵng",
  "Đăk Lăk",
  "Điện Biên",
  "Đồng Nai",
  "Đồng Tháp",
  "Gia Lai",
  "Hà Giang",
  "Hà Nam",
  "Hà Nội",
  "Hà Tây",
  "Hà Tĩnh",
  "Hà Đông",
  "Hòa Bình",
  "Hưng Yên",
  "Hạ Long",
  "Hải Dương",
  "Hải Phòng",
  "Hồ Chí Minh",
  "Khánh Hòa",
  "Kiên Giang",
  "KonTum",
  "Lai Châu",
  "Long An",
  "Lào Cai",
  "Lâm Đồng",
  "Lạng Sơn",
  "Nam Định",
  "Nghệ An",
  "Ninh Bình",
  "Ninh Thuận",
  "Phú Thọ",
  "Phú Yên",
  "Quảng Bình",
  "Quảng Nam",
  "Quảng Ngãi",
  "Quảng Ninh",
  "Quảng Trị",
  "Sóc Trăng",
  "Sơn La",
  "Thanh Hóa",
  "Thái Bình",
  "Thái Nguyên",
  "Thừa Thiên Huế",
  "Tiền Giang",
  "Trà Vinh",
  "Tuyên Quang",
  "Tây Ninh",
  "Vĩnh Long",
  "Vĩnh Phúc",
  "Yên Bái",
  "Khác"
]

export default Cities
